const regionsContainer: HTMLElement = document.querySelector('.nx-regions-group') || document.body,
    dataLayer = (window.dataLayer || { push: () => { } });

regionsContainer.addEventListener('click', (e: Event) => {
    const countryLink = (<HTMLElement>e.target).closest('.nx-country__link');

    if (countryLink == null) return;

    const options = {
        event: 'ga_event',
        eventCategory: 'SiteChooser',
        eventAction: 'Country click',
        eventLabel: `${countryLink.textContent.split('\n').map(s => s.trim()).join(' | ')}`,
        eventNonInteraction: false,
    };

    dataLayer.push(options);
}, true);