export function partial(fn, ...boundArgs) {
    let placeholder = partial;

    let bound = function () {
        let position = 0, length = boundArgs.length;
        let args = Array(length);
        for (let i = 0; i < length; i++) {
            args[i] = boundArgs[i] === placeholder
                ? arguments[position++] : boundArgs[i];
        }
        while (position < arguments.length) {
            args.push(arguments[position++]);
        }
        return fn.apply(this, args);
    };

    return bound;
};

export function curry(func, ...args) {
    return function curriedFunc(...addArgs) {
        args = args.concat(addArgs);
        return (args.length >= func.length)
            ? func.apply(this, args)
            : curriedFunc;
    };
}

export function pipe(...funcs: ((...args) => any)[]): (arg: any) => any {
    return (arg: any) => {
        return funcs.reduce((acc, f) => f(acc), arg);
    }
};

export function tap(func: (arg: any) => void): any {
    return function (arg) {
        func(arg);
        return arg;
    }
};

export function seq(...funcs): any {
    return function (arg) {
        funcs.forEach(f => f(arg));
    }
};

export function alt(func1, func2) {
    return function (arg) {
        return func1(arg) || func2(arg);
    }
};

export function and(func1, func2) {
    return function (arg) {
        return func1(arg) && func2(arg);
    }
}

export function fork(join, func1, func2) {
    return function (arg) {
        return join(func1(arg), func2(arg));
    }
};

export function not(func) {
    return function (arg) {
        return !func(arg);
    }
}