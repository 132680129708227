import { createStore, applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension';

import { rootEpic, rootReducer, RootReducer } from './modules/root';
import { bindStoreToClass, getState$ } from './modules/helpers';

const epicMiddleware = createEpicMiddleware();

const store = createStore(
    rootReducer,
    composeWithDevTools(
        applyMiddleware(epicMiddleware),
    ),
);

epicMiddleware.run(rootEpic);

const ClassWithState = bindStoreToClass(store);
const state$ = getState$(store);
const dispatch = store.dispatch;

export {
    ClassWithState,
    RootReducer,
    state$,
    dispatch,
};

export default store;
