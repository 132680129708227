export const MOVE_RIGHT = 'MOVE_RIGHT';
export const MOVE_LEFT = 'MOVE_LEFT';

export interface NavState {
    position: 'left' | 'right';
    isOpen: boolean;
    navStructure: INavStructure;
}

interface MoveNavRight {
    type: typeof MOVE_RIGHT;
};

interface MoveNavLeft {
    type: typeof MOVE_LEFT;
};

export type NavPositionActionTypes = MoveNavRight | MoveNavLeft;

export const OPEN_NAV = 'OPEN_NAV';
export const CLOSE_NAV = 'CLOSE_NAV';

interface OpenNav {
    type: typeof OPEN_NAV;
};

interface CloseNav {
    type: typeof CLOSE_NAV;
};

export const SET_NAV_STRUCTURE = 'SET_NAV_STRUCTURE';

export interface SetNavStructure {
    type: typeof SET_NAV_STRUCTURE;
    structure: INavStructure;
};

export type NavStateActionTypes = OpenNav | CloseNav;

export type NavActionTypes = NavPositionActionTypes | NavStateActionTypes | SetNavStructure;