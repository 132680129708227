import LayerTiny from '../LayerTiny';
import { BasicLayerOptions } from '../LayerBasic';
import { Optional } from '../../typings/mapped-types';

export interface SmartPushLayerOptions extends BasicLayerOptions {
    afterOpen: Callback;
    afterClose: Callback;
    onCloseBtnClick: Callback;
    smartPushWithContent: Boolean;

}

export default class LayerSmartPush extends LayerTiny {
    protected options: Optional<SmartPushLayerOptions>;

    constructor(element: HTMLElement, options: Optional<SmartPushLayerOptions> = {}) {
        super(element, options);
    }

    protected init(content: string = ''): LayerSmartPush {
        super.init(content);
        this.closeBtn.addEventListener('click', () => {
            this.options.onCloseBtnClick();
        });

        return this;
    }

    protected getDefaultOptions(): Optional<SmartPushLayerOptions> {
        const defOptions = super.getDefaultOptions();

        return {
            ...defOptions,
            layerModifierClasses: `${defOptions.layerMainClass}--spush nx-spush-item`,
            layerCloseBtnModifierClass: `${defOptions.layerMainClass}__close-btn--spush`,
            layerCloseIconClass: 'nx-close-icon nx-close-icon--spush',
            animationDuration: 1400,
            smartPushWithContent: false,
            afterOpen: () => { },
            afterClose: () => { },
            onCloseBtnClick: () => { },
        };
    }
};
