import LayerBasic, { BasicLayerOptions } from './LayerBasic';
import { Optional } from '../typings/mapped-types';
import {
    getOptions,
    requestNextAnimationFrame,
    requestAnimationFrameTimer,
} from '../helpers/helperFunctions';

const moduleSelector = '[data-module="LayerTiny"]';

export default class LayerTiny extends LayerBasic {
    static setup(selector: string = moduleSelector): void {
        Array.from(document.querySelectorAll(selector)).filter((node: HTMLElement) => !node.dataset.moduleInitialized).forEach((element: HTMLElement) => {
            new LayerTiny(element);
            element.dataset.moduleInitialized = 'true';
        });
    }

    constructor(element: HTMLElement, options: Optional<BasicLayerOptions> = {}) {
        super(element, options);

        this.options = getOptions(this.element, this.options);
        this.init();
    }

    protected getDefaultOptions(): Optional<BasicLayerOptions> {
        const defOptions = super.getDefaultOptions();

        return {
            ...defOptions,
            layerModifierClasses: `${defOptions.layerMainClass}--tiny`,
            layerCloseBtnModifierClass: `${defOptions.layerMainClass}__close-btn--tiny`,
            animationDuration: 400,
        };
    }

    async open(callback: Callback = () => { }): Promise<any> {
        const { layerMainClass } = this.options;

        super.open();
        requestNextAnimationFrame(() => this.layer.classList.add(`${layerMainClass}--visible`));

        callback();
    }

    async close(callback: Callback = () => { }): Promise<any> {
        const { layerMainClass, animationDuration } = this.options;
        this.layer.classList.remove(`${layerMainClass}--visible`)
        requestAnimationFrameTimer(() => super.close(), animationDuration);

        callback();
    }
}

// Hot Module Replacement
if (module.hot) {
    let nodesCache: HMRNodes[] = Array.from(document.querySelectorAll(moduleSelector)).map((element: Node) => ({ nodeToReplace: element, nodeOrigin: element.cloneNode(true) }));

    LayerTiny.setup(moduleSelector);

    module.hot.accept(() => {
        LayerTiny.setup(moduleSelector);
    });
    module.hot.dispose(() => {
        nodesCache.forEach(({ nodeToReplace, nodeOrigin }) => { nodeToReplace = nodeToReplace.parentElement.replaceChild(nodeOrigin.cloneNode(true), nodeToReplace) });
    });
} else {
    LayerTiny.setup(moduleSelector);
}

