import {
    FilterState,
    FilterStateTypes,
    FILTER_STATE,
} from './types';

const initialState: FilterState = {
    type: FILTER_STATE,
    filter: {}
};

export default function filterReducer(
    state = initialState,
    action: FilterStateTypes,
): FilterState {
    switch (action.type) {
        case FILTER_STATE:
            return {
                ...state,
                filter: action.filter,
            };
        default:
            return state;
    }
}