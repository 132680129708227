import {
    NavState,
    MOVE_LEFT,
    MOVE_RIGHT,
    OPEN_NAV,
    CLOSE_NAV,
    SET_NAV_STRUCTURE,
    NavActionTypes,
} from './types';

const initialState: NavState = {
    isOpen: false,
    position: 'right',
    navStructure: null,
};

export default function navReducer(
    state = initialState,
    action: NavActionTypes,
): NavState {
    switch (action.type) {
        case MOVE_LEFT:
            return {
                ...state,
                position: 'left',
            };
        case MOVE_RIGHT:
            return {
                ...state,
                position: 'right',
            };
        case OPEN_NAV:
            return {
                ...state,
                isOpen: true,
            };
        case CLOSE_NAV:
            return {
                ...state,
                isOpen: false,
            };
        case SET_NAV_STRUCTURE:
            return {
                ...state,
                navStructure: action.structure,
            };
        default:
            return state;
    }
}