import {
    RESTORE_CART,
    CartActionTypes,
} from './types';

const initialState: CartState = {
    Items: [],
    GiftWrappable: null,
    GiftWrapping: {
        ProductId: '',
        Icon: '',
        Text: '',
    },
    Promotions: [],
    PromotionForm: {
        Toggle: '',
        Button: '',
        Description: '',
        InputPlaceholder: '',
        ErrorEmptyCode: '',
        Close: '',
        Title: '',
        AjaxUrl: '',
        AddPromotionIcon: '',
    },
    Summary: {
        SubTotal: '',
        TotalTax: '',
        DeliveryCost: '',
        OriginalDeliveryCost: '',
        CartDiscounts: null,
        DeliveryDiscounts: [],
        VoucherAmount: '',
        AmountToPay: '',
        TotalPriceWithTax: '',
        TotalPriceWithoutTax: '',
        PaymentRequired: '',
        AmountToPayDecimal: null,
        DeliveryCostDecimal: null,
        TotalTaxDecimal: null,
        CurrencyCode: null,
    },
    DeliveryAddress: null,
    BillingAddress: null,
    SummaryLabel: null,
    CartOverviewLabels: {
        Category: '',
        CheckoutButton: '',
        ContinueButton: '',
        DeliveryFee: '',
        Promotion: '',
        Voucher: '',
        VoucherWillBeSentTo: '',
        FreeDelivery: '',
        Headline: '',
        Remove: '',
        Size: '',
        Subtotal: '',
        Summary: '',
        Tax: '',
        TotalItems: '',
        TotalPrice: '',
        AmountToPay: '',
        UnitPrice: '',
        BundleProducts: '',
    },
    Notification: {
        Duration: 0,
        Icon: '',
        Link: '',
        Style: '',
        Text: '',
        TDefinitions: [],
    },
    CurrentPdpInfo: null,
};

export {
    initialState
};

export default function cartReducer(
    state: CartState = initialState,
    action: CartActionTypes,
): CartState {
    switch (action.type) {
        case RESTORE_CART:
            return {
                ...state,
                ...action.cart,
                Items: [...(action.cart.Items || [])],
                GiftWrapping: {
                    ...(action.cart.GiftWrapping)
                },
                Promotions: [...(action.cart.Promotions || [])],
                PromotionForm: {
                    ...state.PromotionForm,
                    ...action.cart.PromotionForm,
                },
                Summary: {
                    ...action.cart.Summary,
                },
                CurrentPdpInfo: {
                    ...state.CurrentPdpInfo,
                    ...action.cart.CurrentPdpInfo || {},
                }
            };
        default:
            return state;
    }
}
