import {
    addToElement,
    createDiv,
    createElementFromString,
    injectScriptsFromAjaxResponse,
} from '../../helpers/DOMHelpers';

interface Constructor<T> {
    new(...args): T;
}

export default class SmartPushUI {
    protected parent: HTMLElement;
    protected smartPush: HTMLElement;
    protected UIClass: Constructor<BasicLayer>;

    constructor(UIClass: Constructor<BasicLayer>, parent: HTMLElement = document.body) {
        this.parent = parent;
        this.smartPush = createDiv('nx-smart-push');
        this.UIClass = UIClass;

        this.init();
    }

    protected init(): SmartPushUI {
        this.addContainerToElement();

        return this;
    }

    protected addContainerToElement(): SmartPushUI {
        addToElement(this.parent, this.smartPush);
        return this;
    }

    addSPushLayer(spItem: INotificationItem, options?, spItemTrackingData?): BasicLayer {
        const hasContenUrl = spItem.ContentUrl && spItem.ContentUrl != '';
        const hasContent = hasContenUrl || spItem.ContentPart;
        const layerModifierClasses = `${hasContent || spItem.ContentPart ? "nx-layer--content-spush" : 'nx-layer--spush nx-spush-item'} ${spItem.CssClasses}`;

        const layer = new this.UIClass(this.smartPush, { layerModifierClasses, ...options, smartPushWithContent: hasContent }, spItemTrackingData);
        layer.layer.setAttribute("style", spItem.CssStyles);
        let closeBtn = layer.layer.querySelector('.nx-layer__close-btn') as HTMLElement;

        if (hasContenUrl) {
            fetch(spItem.ContentUrl, { credentials: 'include' })
                .then(res => res.text())
                .then(text => {
                    const content = this.getSPushContent(spItem, text);
                    layer.insertContent(content)
                        .open(() => injectScriptsFromAjaxResponse(content));
                })
        } else {
            const content = this.getSPushContent(spItem, spItem.ContentPart);
            layer.insertContent(content)
                .open(() => injectScriptsFromAjaxResponse(content));
        }

        this.closeOnHashChange(closeBtn);

        return layer;
    }

    private closeOnHashChange(closeBtn: HTMLElement) {
        const callback = () => {
            closeBtn?.click();
            window.removeEventListener('hashchange', callback);
        };

        window.addEventListener('hashchange', callback);
    }

    protected getSPushContent({ Text, Icon, LinkHref, PromotionId, ExperienceId }: INotificationItem, content?): HTMLElement {
        const iconHTML = Icon ? `<div class="nx-spush-item__icon">${Icon}</div>` : '';
        const textHTML = Text ? `<div class="nx-spush-item__text">${Text}</div>` : '';
        const promotionData = (PromotionId) ? `data-push-promotion-id="${PromotionId}"` : ``;

        const spNode = createElementFromString(`
            <div class="${content ? "nx-spush-item__content-zone" : "nx-spush-item__content"}" ${promotionData}>
                ${content
                ? `<div class="nx-spush-content-wrapper">${content}</div>`
                : `<${LinkHref ? `a href="${LinkHref}"` : 'div'} class="nx-spush-item__text-wrapper" ${ExperienceId ? `data-experience-id="${ExperienceId}"` : ''}">
                        ${iconHTML} ${textHTML}
                     </>`}
            </div>
        `);

        return spNode;
    }
};
