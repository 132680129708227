import {
    SPushState,
    SPushStateTypes,
    OPEN_SPUSH,
    CLOSE_SPUSH,
    OPEN_TIMER_SPUSH,
    CLOSE_TIMER_SPUSH,
    OPEN_CONTENT_ZONE_SPUSH,
    HIDE_CONTENT_ZONE_SPUSH,
    CLOSE_CONTENT_ZONE_SPUSH,
    INIT_RECURING_STORE,
    PAUSE_SMART_PUSH,
    START_SMART_PUSH,
} from './types';

const initialState: SPushState = {
    isOpen: false,
    isTimerTypeOpen: false,
    openedContentZones: {},
    closedByUserContentZones: {},
    recuringSPushes: {},
    count: 0,
    pauseSmartPush: false
};

export default function smartPushReducer(
    state = initialState,
    action: SPushStateTypes,
): SPushState {
    switch (action.type) {
        case OPEN_SPUSH:
            return {
                ...state,
                count: state.count + 1,
                isOpen: true,
            };
        case CLOSE_SPUSH:
            return {
                ...state,
                count: state.count - 1,
                isOpen: state.count - 1 > 0,
            };
        case OPEN_TIMER_SPUSH:
            return {
                ...state,
                isTimerTypeOpen: true,
                recuringSPushes: {
                    ...state.recuringSPushes,
                    [action.spId]: {
                        lastShown: Date.now(),
                    }
                },
            };
        case CLOSE_TIMER_SPUSH:
            return {
                ...state,
                isTimerTypeOpen: false,
            };
        case OPEN_CONTENT_ZONE_SPUSH:
            return {
                ...state,
                openedContentZones: {
                    ...state.openedContentZones,
                    [action.spId]: true,
                },
            };
        case HIDE_CONTENT_ZONE_SPUSH:
            const { [action.spId]: _, ...rest } = state.openedContentZones;

            return {
                ...state,
                openedContentZones: rest,
            };
        case CLOSE_CONTENT_ZONE_SPUSH:
            return {
                ...state,
                closedByUserContentZones: {
                    ...state.closedByUserContentZones,
                    [action.spId]: true,
                },
                recuringSPushes: {
                    ...state.recuringSPushes,
                    [action.spId]: {
                        lastShown: Date.now(),
                    },
                },
            };
        case INIT_RECURING_STORE:
            const { recuringSPushes } = state;
            return {
                ...state,
                recuringSPushes: { ...recuringSPushes, ...action.store },
            };
        case PAUSE_SMART_PUSH:
            return {
                ...state,
                pauseSmartPush: true,
            };
        case START_SMART_PUSH:
            return {
                ...state,
                pauseSmartPush: false,
            };
        default:
            return state;
    }
};
