import {
    createElementFromString, setFocusIntoLayer,
} from '../helpers/DOMHelpers';

import { Optional } from '../typings/mapped-types';

export interface BasicLayerOptions {
    layerMainClass: string;
    layerCloseBtnModifierClass: string;
    layerCloseIconClass: string;
    layerModifierClasses: string;
    parent: HTMLElement;
    animationDuration: number;
    btnWithBackgroundClass: string;
}

export default class BasicLayer {
    protected element: HTMLElement;
    protected options: Optional<BasicLayerOptions>;
    public layer: HTMLElement;
    protected wrapper: HTMLElement;
    protected layerContent: HTMLElement;
    protected closeBtn: HTMLElement;
    backBtn: HTMLElement;

    constructor(element: HTMLElement, options: Optional<BasicLayerOptions> = {}) {
        this.element = element;
        this.options = { ...this.getDefaultOptions(), ...options };
    }

    protected init(content: string = ''): BasicLayer {
        const { layerMainClass } = this.options;

        this.layer = this.createLayer(content);
        this.wrapper = this.layer.querySelector(`.${layerMainClass}__wrapper`);
        this.layerContent = this.layer.querySelector(`.${layerMainClass}__content`);
        this.closeBtn = this.layer.querySelector(`.${layerMainClass}__close-btn`);
        this.backBtn = this.layer.querySelector('.nx-layer__back-btn');
        this.closeBtn.addEventListener('click', () => this.close());
        this.backBtn.addEventListener('click', () => this.goBack());

        return this;
    }

    protected createLayer(content: string = ''): HTMLElement {
        const { layerMainClass, layerModifierClasses, layerCloseIconClass, layerCloseBtnModifierClass } = this.options;

        return createElementFromString(`
            <div class="${layerMainClass} ${layerModifierClasses}">
                <div class="${layerMainClass}__wrapper">
                    <div class="${layerMainClass}__first-focus" tabindex=0></div>
                    <div class="${layerMainClass}__close-btn ${layerCloseBtnModifierClass}">
                        <div class="${layerCloseIconClass}"></div>
                    </div>
                    <div class="nx-layer__back-btn">                         
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M20.078,24.854a.8.8,0,0,1-.563-.231L11.359,16.57a.8.8,0,0,1,0-1.139l8.156-8.055A.8.8,0,0,1,20.64,8.515L13.06,16l7.58,7.485a.8.8,0,0,1-.562,1.369Z"/></svg>
                    </div>
                    <div class="${layerMainClass}__content">${content}</div>
                </div>
            </div>
        `);
    }

    protected getDefaultOptions(): Optional<BasicLayerOptions> {
        return {
            layerMainClass: 'nx-layer',
            layerCloseIconClass: 'nx-close-icon nx-close-icon--blue',
            btnWithBackgroundClass: 'nx-layer__button--bg',
            layerModifierClasses: '',
            layerCloseBtnModifierClass: '',
            animationDuration: 400,
            parent: this.element,
        }
    }

    async open(callback: Callback = () => { }): Promise<any> {
        const { parent } = this.options;
        parent.appendChild(this.layer);

        callback();

        setFocusIntoLayer(this.layer);
    }

    async close(callback: Callback = () => { }): Promise<any> {
        const { btnWithBackgroundClass } = this.options;
        document.dispatchEvent(new CustomEvent('NiveaXCloseLayer', { detail: { layerNode: this.layer } }));
        this.layer.remove();
        if (this.closeBtn.classList.contains(btnWithBackgroundClass)) {
            this.removeCloseBtnModifierClass(btnWithBackgroundClass);
        }
        callback();
    }

    goBack() {
        window.history.back();
    }

    insertContent(content: HTMLElement, callback: Callback = () => { }): BasicLayer {
        const lastContent = this.layerContent.firstElementChild;

        if (lastContent && lastContent !== content) {
            this.layerContent.innerHTML = '';
        }

        this.layerContent.appendChild(content);
        this.closeBtnModifierClass();

        callback();

        return this;
    }

    closeBtnModifierClass() {
        const { layerModifierClasses, btnWithBackgroundClass } = this.options;

        if (layerModifierClasses == "nx-layer--page" && !(this.layer.querySelector('.nx-overlay__title'))) {
            this.closeBtn.classList.add(btnWithBackgroundClass);
            this.backBtn.classList.add(btnWithBackgroundClass);
        } else {
            this.removeCloseBtnModifierClass(btnWithBackgroundClass);
        }
    }

    removeCloseBtnModifierClass(modifClass) {
        this.closeBtn.classList.remove(modifClass);
        this.backBtn.classList.remove(modifClass);
    }

    getElement(): HTMLElement {
        return this.element;
    }
}