export const FOCUS_TAB = 'FOCUS_TAB';
export const BLUR_TAB = 'BLUR_TAB';

export interface TabState {
    isActive: boolean;
}

interface FocusTab {
    type: typeof FOCUS_TAB;
};

interface BlurTab {
    type: typeof BLUR_TAB;
};

export type TabStateTypes = FocusTab | BlurTab;