import { combineEpics } from 'redux-observable';
import { combineReducers } from 'redux';

import navReducer from './CircleNavigation/reducer';
import smartPushReducer from './SmartPush/reducer';
import tabReducer from './Tab/reducer';
import cartReducer from './ShopCart/reducer';
import filterReducer from './Filter/reducer';

export const rootEpic = combineEpics(
    
);

export const rootReducer = combineReducers({
    circleNav: navReducer,
    smartPush: smartPushReducer,
    tab: tabReducer,
    shopCart: cartReducer,
    filter: filterReducer,
});

export type RootReducer = ReturnType<typeof rootReducer>;