import {
    TabState,
    TabStateTypes,
    BLUR_TAB,
    FOCUS_TAB,
} from './types';

const initialState: TabState = {
    isActive: false,
};

export default function tabReducer(
    state = initialState,
    action: TabStateTypes,
): TabState {
    switch (action.type) {
        case FOCUS_TAB:
            return {
                ...state,
                isActive: true,
            };
        case BLUR_TAB:
            return {
                ...state,
                isActive: false,
            };
        default:
            return state;
    }
};
