/// <reference path="../../typings/niveax.d.ts"/>
/// <reference path="../types.d.ts"/>

import {
    of,
    timer,
    combineLatest,
} from 'rxjs';

import {
    filter,
    mergeMap,
    map,
    mapTo,
    first,
    distinctUntilChanged,
} from 'rxjs/operators';

import store, { state$, dispatch } from '../../store';
import { initRecuringStore } from '../../store/actions';

import { pipe, not, and } from '../../helpers/functional';
import { convertToPlain, isInViewPort } from '../../helpers/DOMHelpers';
import { parseJSONSafe, isNotUndefined, isObjectEmpty } from '../../helpers/helperFunctions';
import { Optional } from '../../typings/mapped-types';

const concatTimer = (acc, itm) => {
    let { SmartPushes, Delay } = itm;
    acc.SmartPushes = acc.SmartPushes.concat(SmartPushes);
    acc.Delay = Delay;

    return acc;
};

const getNotifications = (selector: string): INotificationItem[] =>
    Array.from(<NodeListOf<HTMLElement>>document.querySelectorAll(selector))
        .map(element => parseJSONSafe(element.innerHTML));

const getTimerNotifications = (): TimerNotificationGroup =>
    getNotifications('[data-smart-push]')
        .reduce(concatTimer, { SmartPushes: [], Delay: 5000 } as TimerNotificationGroup);

const getScrollNotification = (): INotificationItem[] =>
    Array.from(<NodeListOf<HTMLElement>>document.querySelectorAll('[data-cp-smart-push]'))
        .reduce((acc, element) => {
            const contentZone = element.closest('.nx-content-zone');
            if (contentZone) {
                const notification = parseJSONSafe(element.innerHTML);
                notification.DOMElement = contentZone;
                notification.ContentPart = notification.ContentHtml ? convertToPlain(notification.ContentHtml) : false;

                acc.push(notification);
            }

            return acc;
        }, []);


export const getPushNotificationStore = (): Optional<PushNotificationStore> => {
    const store: Optional<PushNotificationStore> = {};

    const timerNotifications = getTimerNotifications();
    if (timerNotifications) store.timer = timerNotifications;

    const contentZoneNotifications = getScrollNotification();
    if (contentZoneNotifications) store.scroll = contentZoneNotifications;

    return store;
}

export const navIsClose$ = state$.pipe(filter(state => state.circleNav.isOpen === false));

export const tabIsActive$ = state$.pipe(filter(state => state.tab.isActive === true));

export const shouldPauseSmartPush$ = combineLatest(
    state$.pipe(map(state => state.tab.isActive)),
    state$.pipe(map(state => !state.circleNav.isOpen)),
    state$.pipe(map(state => !state.smartPush.pauseSmartPush))
).pipe(
    filter((states: boolean[]) => states.every(s => s === true))
);

export const timerSPushIsClosed$ = state$.pipe(filter(state => state.smartPush.isTimerTypeOpen === false));

export const isContentZoneSPushOpened$ = (spItem: INotificationItem): any =>
    state$.pipe(
        filter(state => !(spItem.Id in state.smartPush.openedContentZones)),
        mapTo(spItem),
        first(),
    );

export const contentZoneSPushIsClosed$ = (spId: string) => state$.pipe(filter(state => spId in state.smartPush.closedByUserContentZones));

export const closeLayerAfterTime = (layer: BasicLayer, duration: number) =>
    of(duration).pipe(
        filter(isNotUndefined),
        mergeMap(duration => timer(duration * 1000 + 900)), // 900 --> animation time
    ).subscribe(() => layer.close());

export const getSPushLayerOptions = (spItem: INotificationResultItem) => Object.entries(spItem)
    .filter(([_, value]) => typeof value === 'function')
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

export const getSpushTrackingData = (spItem: INotificationResultItem) => {
    return spItem.TDefinitions;
}

export const isContentZoneInViewport = (element: HTMLElement) => {
    const { top: elementTop, bottom: elementBottom, height: elementHeight } = element.getBoundingClientRect(),
        documentHeight = document.documentElement.offsetHeight;

    if (isInViewPort(element)) {
        if (documentHeight > elementHeight * 1.3) {
            if (elementTop > 0 && documentHeight > elementBottom) return true;
        } else {
            if (elementTop <= 0 && elementBottom >= documentHeight) return true; // center of the element is in viewport
            if (elementTop > 0 && documentHeight > elementTop && documentHeight * 0.6 < documentHeight - elementTop) return true; // top part of element is in viewport and is bigger then 60% of viewport
            if (documentHeight > elementBottom && documentHeight * 0.6 < elementBottom) return true; // bottom part of element is in viewport and is bigger then 60% of viewport
        }
    }

    return false;
};

/*
*
* Recuring helpers
*
*/

const readRecuringStore = (): IRecuringStore =>
    parseJSONSafe(localStorage.getItem('recuringSPushes'))
    || {};

const initRecuringRedux = (recuringStore: IRecuringStore) =>
    dispatch(initRecuringStore(recuringStore));

export const initRecuring = pipe(readRecuringStore, initRecuringRedux);

const getMilliseconds = (hours: number): number => {
    return hours
        ? hours * 60 * 60 * 1000
        : 0;
}

// Only for TIMER smart-pushes
export const filterItemsToRecur = ([type, items]: PushNotificationGroup): PushNotificationGroup => {
    if (type === 'timer') {
        const SmartPushes = (<TimerNotificationGroup>items).SmartPushes.filter(and(isNotUndefined, filterSPushesToShow));
        items = { ...items, SmartPushes };
    }
    // } else {
    //     items = items.filter(filterSPushesToShow);
    // }

    return [type, items];
}

const filterSPushesToShow = ({ Id, RecurringHours }: INotificationItem) => {
    const { recuringSPushes } = store.getState().smartPush;
    const storageItem = recuringSPushes[Id];

    if (!storageItem) return true;

    if (RecurringHours && timeToShow(storageItem.lastShown, RecurringHours)) {
        return true;
    }

    return false;
}

const timeToShow = (lastShown: number, recurringHours: number) => {
    return lastShown + getMilliseconds(recurringHours) <= Date.now();
}

export const recuringSPushesChanges$ = state$.pipe(
    map(state => state.smartPush.recuringSPushes),
    filter(not(isObjectEmpty)),
    distinctUntilChanged(),
);


export const getLoyaltySmartPush = (): LoyaltySmartPushIds => {
    const smartPushRepo = <HTMLElement>document.querySelector('[data-smartpush-loyalty-points]'),
        getLoyaltyTemplates = smartPushRepo ? smartPushRepo.getAttribute('data-smartpush-loyalty-points') : null,
        smartPushTemplateIds = getLoyaltyTemplates ? parseJSONSafe(getLoyaltyTemplates) : null;

    return smartPushTemplateIds;
}