import { Observable } from 'rxjs';
import { Store } from 'redux';

import { RootReducer } from './root';

export function getState$(store: Store<RootReducer>) {
    return new Observable(function (observer) {
        observer.next(store.getState());

        const unsubscribe = store.subscribe(function () {
            observer.next(store.getState());
        });
        
        return unsubscribe;
    }) as Observable<RootReducer>;
}

class EmptyClass {}

interface Base {}

interface Storable {
    state$: Observable<RootReducer>;
    dispatch: (action: any) => any;
}

export const bindStoreToClass = (store: Store<RootReducer>) => 
    function ClassWithState<T>(
        SuperClass: Constructable<T> = EmptyClass as Constructor<T>
    ) {
        class C extends (<Constructor<Base>>SuperClass) {
            protected dispatch: (action: any) => any;
            protected state$: Observable<RootReducer>;

            constructor(...args) {
                super(...args);

                this.dispatch = store.dispatch;
                this.state$ = getState$(store);
            }
        }

        return (<Constructor<T & Storable>>(<unknown>C));
    };