import UI from '../PushNotification/ui/SmartPushUI';
import LayerSmartPush from '../modules/LayerSmartPush/LayerSmartPush';
import { closeLayerAfterTime } from '../PushNotification/helpers/index';

const ui = new UI(LayerSmartPush);

export default function initGeoLocationChecker(dataAttrName: string): void {
    const node = <HTMLElement>document.body.querySelector(`[${dataAttrName}]`);
    const spSiteChooserURL = node && node.getAttribute(dataAttrName);

    if (!spSiteChooserURL) return;

    fetch(spSiteChooserURL, {credentials: 'include'})
        .then(res => res.json())
        .then(spItm => { 
            const layer = ui.addSPushLayer(spItm);
            if (spItm.Duration)
                closeLayerAfterTime(layer, spItm.Duration);
        })
        .catch(error => console.error(error));
};
