/// <reference path="../../../PushNotification/types.d.ts"/>

export const OPEN_SPUSH = 'OPEN_SPUSH';
export const CLOSE_SPUSH = 'CLOSE_SPUSH';

export const OPEN_TIMER_SPUSH = 'OPEN_TIMER_SPUSH';
export const CLOSE_TIMER_SPUSH = 'CLOSE_TIMER_SPUSH';

export const OPEN_CONTENT_ZONE_SPUSH = 'OPEN_CONTENT_ZONE_SPUSH';
export const HIDE_CONTENT_ZONE_SPUSH = 'HIDE_CONTENT_ZONE_SPUSH';
export const CLOSE_CONTENT_ZONE_SPUSH = 'CLOSE_CONTENT_ZONE_SPUSH';

export const INIT_RECURING_STORE = 'INIT_RECURING_STORE';
export const WRITE_RecurING_ITEM = 'WRITE_RecurING_ITEM';

export const PAUSE_SMART_PUSH = "PAUSE_SMART_PUSH";
export const START_SMART_PUSH =  "START_SMART_PUSH";

interface IOpenedContentZones {
    [index: string]: true;
}

export interface SPushState {
    isOpen: boolean;
    isTimerTypeOpen: boolean;
    openedContentZones: IOpenedContentZones;
    closedByUserContentZones: IOpenedContentZones;
    recuringSPushes: IRecuringStore;
    count: number;
    pauseSmartPush: boolean;
}

interface OpenSPush {
    type: typeof OPEN_SPUSH;
};

interface CloseSPush {
    type: typeof CLOSE_SPUSH;
};

interface OpenTimerSPush {
    type: typeof OPEN_TIMER_SPUSH;
    spId: string;
};

interface CloseTimerSPush {
    type: typeof CLOSE_TIMER_SPUSH;
}

interface OpenContentZoneSPush {
    type: typeof OPEN_CONTENT_ZONE_SPUSH;
    spId: string;
}

interface HideContentZoneSPush {
    type: typeof HIDE_CONTENT_ZONE_SPUSH;
    spId: string;
}

interface CloseContentZoneSPush {
    type: typeof CLOSE_CONTENT_ZONE_SPUSH;
    spId: string;
}

interface InitRecuringStore {
    type: typeof INIT_RECURING_STORE;
    store: IRecuringStore;
}

interface PauseSmartPush {
    type: typeof PAUSE_SMART_PUSH;
};

interface StartSmartPush {
    type: typeof START_SMART_PUSH;
};


export type SPushStateTypes =   OpenSPush | CloseSPush
                                | OpenTimerSPush | CloseTimerSPush 
                                | OpenContentZoneSPush | HideContentZoneSPush | CloseContentZoneSPush
                                | InitRecuringStore | PauseSmartPush | StartSmartPush;